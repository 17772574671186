import React, {useEffect} from 'react';
import { gsap, Power3 } from 'gsap/all';
import { Link, animateScroll as scroll } from "react-scroll";

const Navbar = () => {
    useEffect(() => {
        const tlNav = gsap.timeline({ defaults: { duration: 1 }});
        tlNav
            .fromTo(".rect-logo", {
                opacity:0,
                scaleX:0,
                scaleY:0,
                transformOrigin:"center", 
                marginTop:0, 
                ease:Power3.easeIn
            },{
                opacity: 1,
                scaleX:1,
                scaleY:1,
            }, '<.2')
            .fromTo(".main-logo", {
                opacity:0,
                scaleX:0,
                scaleY:0,
                transformOrigin:"center", 
                marginTop:0, 
                ease:Power3.easeOut
            },{
                opacity: 1,
                scaleX:1,
                scaleY:1,
            }, '<.5')
            .from(".ayei", {
                x: '150',
                autoAlpha: 0,
                ease: Power3.out,
            }, '<.3')
            .from(".navLink", {opacity:0, stagger: 0.5, y: -100, ease:Power3.out}, '<.3')
    }, [])
    return (
        <nav className="py-6 fixed w-full bg-wash z-50">
            <div className="relative flex items-center justify-between h-16 max-w-screen-2xl mx-auto px-6 sm:px-6 lg:px-14 z-40">
                <div className="flex-1 flex items-stretch justify-start relative">
                    <div className="flex-shrink-0 flex items-center uppercase font-title text-xl sm:text-2xl text-asphalt cursor-pointer" onClick={() =>{scroll.scrollToTop()}}>
                        <svg className="mr-4 h-14 w-auto" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect className="rect-logo" y="3" width="50" height="50" fill="#DD5C5C"/>
                            <path className="main-logo" d="M12 11V28M12 11L20.2823 28M12 11H20.2613M28.5226 11C31.0946 11.2272 36.9097 12.7895 36.9097 19.4361C36.9097 26.0827 31.5699 27.7302 28.5645 28M28.5226 11L20.2823 28M28.5226 11H20.2613M28.5226 11H38V45H28.5226M28.5645 28C31.2344 28.5823 38 28 38 36.3935C38 44.787 31.4301 44.3467 28.5226 45M28.5645 28H20.2823M12 28V45H20.2613M12 28H20.2823M28.5226 45L20.2823 28M28.5226 45H20.2613M20.2823 28L20.2613 45M20.2823 28L20.2613 11" stroke="white" stroke-width="2"/>
                        </svg>
                        <span className="inline-block ayei hidden sm:block">Ayei Bithao</span>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <div className="flex space-x-4 menu">
                            <Link activeClass="active" to="Works" smooth={true} offset={-70} duration={500} className="text-asphalt hover:text-beets px-3 py-2 text-lg font-subtitle uppercase cursor-pointer navLink">Works</Link>
                            <Link activeClass="active" to="About" smooth={true} offset={-70} duration={6000} className="text-asphalt hover:text-beets px-3 py-2 text-lg font-subtitle uppercase cursor-pointer navLink">About</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
