import React, { useEffect, useRef } from "react";
import { projects } from "../data";
import { gsap, Power3 } from 'gsap/all';
import Tilt from 'react-tilt';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Emoji from 'a11y-react-emoji';
gsap.registerPlugin(ScrollTrigger); 

function Projects() {
    const portfolioRef = useRef([]);
    let tl = useRef();
    portfolioRef.current = [];
    tl = gsap.timeline({ defaults: {delay: 0.1}})
    useEffect(() => {
        
        portfolioRef.current.map((el) => {
            const w = el.querySelector('.portfolio-info');
            const p = el.querySelector('.portfolio-img');
            tl.from(w, 2, {opacity:0, ease:Power3, x:'-150', scrollTrigger: {trigger: el, start: 'top center', end: 'top center', pin:true, markers: false, scrub: 0.5, toggleActions: "restart pause reverse reset"}});
            tl.from(p, 2, {opacity:0, x:'150', scrollTrigger: {trigger: el, start: 'top center', end: 'top center', pin:true, markers: false, scrub: 0.5, toggleActions: "restart pause reverse reset",}});
           return el
        }
        );
      }, []);

    const addToRefs = (el) => {
        if(el && !portfolioRef.current.includes(el)){
            portfolioRef.current.push(el);
        }
    };

    return (
        <div className="portfolio-wrapper relative space-y-8" id="Works">
            {projects.map((project, id) => (
                <section key={id} className="w-full portfolio-wrapper block relative">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 relative inline-flex items-center pb-24 w-full" ref={addToRefs}>
                        <div className="space-y-6 portfolio-info">
                            <span className="text-base font-subtitle text-cement block pb-2">{project.date}</span>
                            <h2 className="text-5xl sm:text-6xl font-title text-beets">{project.client}</h2>
                            <h3 className="text-2xl sm:text-3xl font-subtitle text-lilac w-full sm:w-1/2">{project.subtitle}</h3>
                            <p className="text-base font-body text-lilac"><b>Tools:</b> {project.tools} </p>       
                            { project.link && project.link.length? (
                                 <a href={project.link} title={project.client} target="_blank" rel="noreferrer" className="inline-block px-10 py-5 right-10 bottom-10 border-2 rounded-2xl border-asphalt bg-white text-asphalth hover:bg-beets hover:text-white font-body text-base z-50">View Website <Emoji symbol="🌎" label="link" /></a>
                            ):"" }
                        </div>
                        <div className="space-y-3 flex flex-wrap justify-center portfolio-img relative">      
                                <Tilt className="Tilt z-20" options={{ max : 20}}>
                                    <img src={project.image} alt={project.client} className="align-middle relative"/>
                                </Tilt> 
                        </div>
                    </div>
                </section>
            ))}
        </div>
    );
}

export default Projects;