import React from 'react';
import Emoji from 'a11y-react-emoji';

function Footer() {
    return (
        <div className="bg-asphalt text-center font-subtitle py-6 text-wash relative">
            <span className="text-2xl align-middle">&copy;</span> 2023 Ayei Bithao  <Emoji symbol="✨" label="sparkle" />  Made with <Emoji symbol="💕" label="love" /> in React   <Emoji symbol="✨" label="sparkle" />   All Right Reserved
        </div>
    )
}

export default Footer
