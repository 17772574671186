import React from 'react';
import ReactTooltip from 'react-tooltip';
import Clipboard from 'react-clipboard.js';
import Emoji from 'a11y-react-emoji';

function Contact() {
    return (
        <div className="relative py-32 ct-body">
            <h2 className="text-5xl sm:text-6xl font-title text-sand text-center">Let's <span className="text-beets">Collaborate</span> Together</h2>
            <div className="grid grid-cols-1 sm:grid-cols-7 gap-4 space-y-8 sm:space-y-0 relative inline-flex items-center pt-24 pb-12">
                <div className="col-span-3"><h3 className="text-xl font-subtitle text-lilac ct-title">Don't be a stranger! <Emoji symbol="😉" label="wink" /> <br/> Just say hi <Emoji symbol="👋" label="hello" /> or contact me for a freelance project.</h3></div>
                <div className="col-span-4 text-base font-body text-lilac space-y-4 ct-text">
                    <p>I am quite an organized girl who always keeps her inbox clean so rest assured I'll see your message and will reply to you right away. Trust me.</p></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 items-center justify-center">
                <div className="flex flex-wrap justify-center">
                    <Clipboard data-tip='Copied!' data-event='click' data-clipboard-text="alyssabithao@gmail.com" className="cursor-pointer text-base px-10 py-2 bg-white border-2 border-asphalt hover:bg-beets hover:text-white text-asphalt font-body rounded-2xl">Get Email <Emoji symbol="📧" label="email" /></Clipboard>
                    <ReactTooltip globalEventOff='click' place="bottom" effect="solid" className="font-body"/>
                </div>
                <div className="flex flex-wrap justify-center"><a href="https://www.instagram.com/ayeibithao/" data-tip data-for='instagram' target="_blank" rel="noopener noreferrer" title="Instagram" className="px-10 py-2 bg-white border-2 border-asphalt hover:bg-beets hover:text-white text-asphalt font-body rounded-2xl">Instagram <Emoji symbol="📷" label="camera" /></a>
                <ReactTooltip id='instagram' place="bottom" effect="solid">
                    <span className="font-body">Let's connect on Instagram! <Emoji symbol="📷" label="camera" /></span>
                </ReactTooltip>
                </div>
                <div className="flex flex-wrap justify-center"><a href="https://www.linkedin.com/in/ayeibithao/" data-tip data-for='linkedin' target="_blank" rel="noopener noreferrer" title="Linkedin" className="px-10 py-2 bg-white border-2 border-asphalt hover:bg-beets hover:text-white text-asphalt font-body rounded-2xl">Linkedin <Emoji symbol="📑" label="linkedin" /></a>
                <ReactTooltip id='linkedin' place="bottom" effect="solid">
                    <span className="font-body">Check my LinkedIn because resumes are old fashioned now <Emoji symbol="📝" label="note" /></span>
                </ReactTooltip>
                </div>
                <div className="flex flex-wrap justify-center"><a href="https://forms.gle/cgYLWLdaWHyz5VeN8" data-tip data-for='cv' target="_blank" title="Curriculum Vitae" rel="noopener noreferrer" className="px-10 py-2 bg-white border-2 border-asphalt hover:bg-beets hover:text-white text-asphalt font-body rounded-2xl">CV <Emoji symbol="📥" label="download" /></a>
                <ReactTooltip id='cv' place="bottom" effect="solid">
                    <span className="font-body">You can also download my resume too! <Emoji symbol="✨" label="sparkle" /></span>
                </ReactTooltip></div>
            </div>
        </div>
    )
}

export default Contact;

