export const projects = [
      {
        date: "2020-2021",
        client: "Kimona Pinoy",
        subtitle: "Filipino Heritage To The World",
        tools: "Figma, HTML5, TailwindCSS, WordPress, WooCommerce, JavaScript",
        image: "/images/KimonaPinoy.png",
        link: "https://www.kimonapinoy.com/",
        id:180,
     },
     {
        date: "2021",
        client: "Mind Talker",
        subtitle: "7 Deadly Sins, 1 Telepathetic Detective",
        tools: "Figma, HTML5, TailwindCSS, WordPress",
        image: "/images/MindTalker.png",
        link: "https://mindtalkermovie.com/",
        id:181,
      },
      {
        date: "2021",
        client: "Kinsfolk Coffee",
        subtitle: "Your Every Coffee, Made Better",
        tools: "Figma, HTML5, TailwindCSS, WordPress, WooCommerce, JavaScript",
        image: "/images/KinsfolkCoffee.png",
        id:182,
      },
      {
        date: "2016-2019",
        client: "Topcat Bookings",
        subtitle: "Everything Entertainment,Connected Worldwide",
        tools: "Figma, Adobe Photoshop, Ruby on Rails, HAML/HTML5, SASS, CoffeeScript",
        image: "/images/TopcatBooking.png",
        id:183,
      },
      {
        date: "2015",
        client: "Heneral Luna",
        subtitle: "Bayan o Sarili?",
        tools: "Adobe Photoshop, HTML5, CSS3, WordPress, WooCommerce, JavaScript",
        image: "/images/HeneralLuna.png",
        id:184,
      },

  ];