import React from 'react';
import { init } from 'ityped';
import { gsap, Power3 } from 'gsap/all';
import { useEffect, useRef } from 'react';
import Emoji from 'a11y-react-emoji';
import { Link } from "react-scroll";

function Intro() {

    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, { 
            backDelay: 1500,
            backSpeed: 60,
            showCursor: true,
            startDelay: 1500,
            cursorChar: "|",
            strings: ['Figma', 'WordPress', 'React', 'HTML5', 'SASS', 'Tailwind CSS', 'BootStrap', 'UX Design', 'Git', 'CSS3', 'GSAP', 'JavaScript', 'SEO', 'Google Analytics', 'UI Design', 'MySQL', 'Webpack', 'Styled Components'],
        });
    }, []);

    

    useEffect(() => {
        const tlIntro = gsap.timeline({ defaults: { duration: 1 }});
        tlIntro
            .from("h2 > span", {
                x: '-150',
                autoAlpha: 0,
                ease: Power3.out,
                stagger: 0.5
            }, 1)
            .from("h3",{
                y: 150,
                autoAlpha: 0,
                ease: Power3.in,
            }, '<.5')
            .from("p", {
                y: 150,
                autoAlpha: 0,
                ease: Power3.in,
            }, '<.5')
            .fromTo(".circle", {
                opacity:0,
                scaleX:0,
                scaleY:0,
                transformOrigin:"center", 
                marginTop:0, 
                ease:Power3.easeIn
            },{
                opacity: 1,
                scaleX:1,
                scaleY:1,
            }, '<.2')
            .from(".arrowsvg", {
                opacity: 0,
                y:-50,
                ease:Power3.easeOut
            },'<.3')
            .from(".portfolioLink", {
                x: '150',
                autoAlpha: 0,
                ease: Power3.out,
            }, '<.4')
    }, [])

    return (
        <div className="relative pt-14 sm:pt-52 space-y-16 sm:space-y-44 h-screen" id="Hero">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 space-y-8 pt-24">
                <div className="w-3/4">
                    <h2 className="text-5xl sm:text-6xl font-title text-sand pb-4">
                        <span className="block">Currently</span>
                        <span className="block">working on</span></h2>
                    <span className="text-5xl sm:text-6xl font-title text-beets" ref={textRef}></span>
                </div>
                <div className="space-y-8">
                    <h3 className="text-4xl font-title text-beets">Hello! <Emoji symbol="👋" label="hello" /></h3>
                    <p className="text-base sm:text-xl font-body text-lilac">My name is Ayei, a <b>creative designer and a full stack developer</b> based in Philippines.</p>
                </div>
            </div>
            <Link className="text-asphalt font-subtitle inline-flex items-center cursor-pointer hover:text-beets" activeClass="active" to="Works" spy={true} smooth={true} offset={-70} duration={500}>
                <svg className="w-12 h-12 mr-4 inline-block" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="24.5" fill="white" stroke="#6F6571" className="circle"/>
                    <path d="M34 24.9288L32.1026 23.0464L26.3419 28.7615V16H23.6581V28.7615L17.8974 23.0464L16 24.9288L25 33.8575L34 24.9288Z" fill="#3F4650" className="arrowsvg"/>
                </svg>
                <span className="portfolioLink">Selected Projects</span>
            </Link>
        </div>
    )
}

export default Intro;
