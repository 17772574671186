import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar/index';
import Intro from './components/Intro';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ClockLoader from "react-spinners/ClockLoader";
import Emoji from 'a11y-react-emoji';
import { Helmet } from  'react-helmet';
import './assets/main.css';
import './index.css';
function App() {
  const[loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])
  return (
     <div>
      {
        loading ?
        <div className="flex justify-center items-center bg-wash h-screen">
          <span className="absolute bottom-5 block text-center font-subtitle text-2xl text-asphalth py-12">
            Discovering new ways of making you wait... <Emoji symbol="🤭" label="smirk" />
          </span>
          <ClockLoader color={"#DD5C5C"} loading={loading} size={150} className="block" />
        </div>
        :
        <div>
          <Helmet>
            <title>Ayei Bithao | Digital Designer &amp; Full Stack Developer</title>
          </Helmet>
          <Navbar />
            <div id="main" className="max-w-screen-2xl mx-auto px-6 sm:px-6 lg:px-14 z-40"> 
              <Intro />
              <Projects />
              <About />
              <Contact />
            </div>
          <Footer />
        </div>
        }
    </div>
  );
}
export default App;
