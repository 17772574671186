import React, {useEffect} from 'react';
import image from '../Images';
import { gsap, Power3 } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger); 

function About() {
    
    useEffect(() => {
        const tlAbout = gsap.timeline({ defaults: { 
            duration: 1, 
            scrollTrigger: {
                trigger:'#About',
                scrub: 1,
                pin: true,
                start: 'top center', 
                end: 'top center',
        } }});
        tlAbout
            .from(".at-head > span", {
                opacity: 0,
                y: -50,
                ease:Power3.in
                }, '<.2')
            .from(".at-head > h2", {
                opacity: 0,
                y: 50,
                stagger: 0.3,
                ease:Power3.easeOut       
            },'<.3')
            .from(".brief", {
                opacity: 0,
                x: -100,
                ease:Power3.in
            },'3')
            .from(".summary", {
                opacity: 0,
                x: 100,
                ease:Power3.in
            },'3')
            .from(".exp-left", {
                opacity: 0,
                y: 10,
                stagger: 0.1,
                ease:Power3.easeIn 
            },'3')
            .from(".exp-right", {
                opacity: 0,
                y: 10,
                stagger: 0.1,
                ease:Power3.easeIn 
            },'3')
    }, [])
    return (
        <div className="relative py-24" id="About">
            <div className="text-center at-head">
                <span className="text-sm font-title text-lilac uppercase mb-4 block tracking-widest">Ayei Bithao</span>
                <h2 className="text-5xl sm:text-6xl font-title text-sand">Design To <span className="text-beets">Communicate</span>,</h2>
                <h2 className="text-5xl sm:text-6xl font-title text-sand">Develop To <span className="text-beets">Interact</span>.</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-7 gap-4 space-y-8 sm:space-y-0 relative inline-flex items-center pt-24 pb-12">
                <div className="col-span-3 brief"><h3 className="text-2xl font-subtitle text-lilac"><span className="text-beets">9+ years</span> building website from scratch with a flair in producing responsive and optimized websites.</h3></div>
                <div className="col-span-4 text-base font-body text-lilac space-y-4 summary">
                    <p>Specialized in building websites such as e-commerce, customer web portal, landing pages, portfolio, and film websites.</p>
                    <p>I believe that understanding human experience and behavior is essential for creating functional digital products that provides innovative solutions to make our life easier.</p></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 space-y-8 sm:space-y-0 relative inline-flex items-center py-12">
                <div className="space-y-20">
                    <div className="flex-none sm:flex gap-4 space-y-6 sm:space-y-0 exp-left">
                        <div className="relative w-full items-center justify-center flex sm:flex-none sm:w-32">
                            <img src={image.logoconvene} alt="Convene Global" className="object-cover rounded-2xl w-32 h-32"/>
                        </div>
                        <div className="space-y-2">
                            <span className="text-cement font-subtitle text-lg text-center sm:text-left block">Sep 2021 - Present</span>
                            <h5 className="text-asphalt font-title text-2xl text-center sm:text-left">Convene Global</h5>
                            <p className="text-lilac font-body text-base text-center sm:text-left">Full Stack Web Developer</p>
                        </div>
                    </div>
                    <div className="flex-none sm:flex gap-4 space-y-6 sm:space-y-0 exp-left">
                        <div className="relative w-full items-center justify-center flex sm:flex-none sm:w-32">
                            <img src={image.logoTopcat} alt="Topcat Booking" className="object-cover rounded-2xl w-32 h-32"/>
                        </div>
                        <div className="space-y-2">
                            <span className="text-cement font-subtitle text-lg text-center sm:text-left block">Nov 2015 - Jul 2018</span>
                            <h5 className="text-asphalt font-title text-2xl text-center sm:text-left">Topcat Booking</h5>
                            <p className="text-lilac font-body text-base text-center sm:text-left">Team Lead &amp; Front-end Developer</p>
                        </div>
                    </div>
                </div>
                <div className="space-y-20">
                    <div className="flex-none sm:flex gap-4 space-y-6 sm:space-y-0 exp-right">
                        <div className="relative w-full items-center justify-center flex sm:flex-none sm:w-32">
                            <img src={image.logoBoohawi} alt="Boohawi Studios" className="object-cover rounded-2xl w-32 h-32"/>
                        </div>
                        <div className="space-y-2">
                            <span className="text-cement font-subtitle text-lg text-center sm:text-left block">Sep 2014 - Nov 2015</span>
                            <h5 className="text-asphalt font-title text-2xl text-center sm:text-left">Boohawi Studios</h5>
                            <p className="text-lilac font-body text-base text-center sm:text-left">Website Designer &amp; Full Stack Developer</p>
                        </div>
                    </div>
                    <div className="flex-none sm:flex gap-4 space-y-6 sm:space-y-0 exp-right">
                        <div className="relative w-full items-center justify-center flex sm:flex-none sm:w-32">
                            <img src={image.logo7M} alt="7th Media Design Studio" className="object-cover rounded-2xl w-32 h-32"/>
                        </div>
                        <div className="space-y-2">
                            <span className="text-cement font-subtitle text-lg text-center sm:text-left block">Mar 2013 - Aug 2014</span>
                            <h5 className="text-asphalt font-title text-2xl text-center sm:text-left">7th Media Design Studio</h5>
                            <p className="text-lilac font-body text-base text-center sm:text-left">Digital Designer &amp; Front-end Developer</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
